import axios from "axios";

let baseURL = "";

// 环境的切换
if (process.env.NODE_ENV == "development") {
  // baseURL = "http://1.15.35.122:8082/";
  baseURL = "https://www.hitacad.com/";
  // baseURL = "https://www.hitacad.com/";
} else if (process.env.NODE_ENV == "production") {
  // baseURL = "https://hitacad.ugdap.com/";
  baseURL = "https://www.hitacad.com/";
}
// 创建axios实例
const service = axios.create({
  baseURL: baseURL,
  timeout: 300000, // 请求超时时间
});

// request 拦截器
service.interceptors.request.use(
  (config) => {
    config.headers["channel"] = 1;
    return config;
  },
  (error) => {
    console.log(error); // for debug
    Promise.reject(error);
  }
);
// response 拦截器
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);
export default service;
