import request from "./http";

export function worksList(params) {
  return request({
    url: "/hit-server/works/listAll",
    method: "post",
    params,
  });
}

// 关于我们
export function getAboutUs(params) {
  return request({
    url: "/hit-server/aboutUs/getAboutUs",
    method: "post",
    params,
  });
}

// 联系我们
export function getContactUsInfo(params) {
  return request({
    url: "/hit-server/contactUs/getContactUsInfo",
    method: "post",
    params,
  });
}

// 明星学员
export function getStarList(params) {
  return request({
    url: "/hit-server/star/listAll",
    method: "post",
    params,
  });
}

// 查询全部作品列表
export function queryForWorksList(params) {
  return request({
    url: "/hit-server/works/listAll",
    method: "post",
    data: params,
  });
}

// 查询全部明星学员列表
export function queryForStarsList(params) {
  return request({
    url: "/hit-server/star/listAll",
    method: "post",
    data: params,
  });
}

// 查询明星学员详情
export function queryForStarDetail(params) {
  return request({
    url: "/hit-server/star/getDetail",
    method: "post",
    data: params,
  });
}

// 查询1-关于我们 2-企业合作 3-展会合作 4-孵化器合作
export function getAboutUsList(params) {
  return request({
    url: "/hit-server/aboutUs/getAboutUsList",
    method: "post",
    data: params,
  });
}

// 查询知道类型图片列表
export function getImageList(params) {
  return request({
    url: "/hit-server/img/listAll",
    method: "post",
    data: params,
  });
}

// 查询全部团队列表
export function getTeacherList(params) {
  return request({
    url: "/hit-server/teacher/listAll",
    method: "post",
    data: params,
  });
}

// 查询课程介绍
export function getCourseInfo(params) {
  return request({
    url: "/hit-server/course/getCourseInfo",
    method: "post",
    data: params,
  });
}

// 首页视频
export function homeVideo(params) {
  return request({
    url: "/hit-server/img/detail",
    method: "post",
    data: params,
  });
}
